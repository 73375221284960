 /* Customize the label (the selection_radio) */
 .selection_radio {
  display: block;
  position: relative;
  margin-bottom: 1vw;
  cursor: pointer;
  font-size: 4vw;
  font-family: "Poppins";
  height: 10vw;

  padding-top: 1.5vw;
  text-align: center;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #FFD300;
  border-radius: 35vw;
}


/* Hide the browser's default radio button */
.selection_radio input {
  display:none;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 1.5vw;
  left: 1vw;
  height: 7vw;
  width: 7vw;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.selection_radio:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.selection_radio input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.selection_radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.selection_radio .checkmark:after {
  top: 0.7vw;
  left: 0.7vw;
  width: 5.5vw;
  height: 5.5vw;
  border-radius: 50%;
  background: #000;
}



@media screen and (min-width: 1001px) {

    /* Create a custom radio button */
    .checkmark {
        top: 0.5vw;
        height: 3vw;
        width: 3vw;
    }

    .selection_radio{
        padding-top: 0.8vw;
        font-size: 1.5vw;
        text-align: left;
        padding-left: 5vw;
        height:4vw;
        width: 30%;
    }

    /* Style the indicator (dot/circle) */
    .selection_radio .checkmark:after {
        top: 0.3vw;
        left: 0.3vw;
        width: 2.4vw;
        height: 2.4vw;
    }
}
