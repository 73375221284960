.footer{
    background-color: #000000;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    height: fit-content;
    font-family: "Inter", sans-serif;
    z-index: 100000;
    align-items: center;
}

.call-footer a{
  color: #000000;
}

.call-footer {
  display: grid;
  justify-content: center;
  width: 60vw;
  height: fit-content;
  cursor: pointer;
  margin-top: 5%;
  z-index: 1000;

  background-color: #FFD300;
  border-radius: 35vw;
}

.call-footer .floating-button .button-title {
  position: relative;
}

.img-container-footer {
  width: 65vw;
  z-index: 1;
}
.img-container-footer img {
  width: 100%;
  margin-top: 4vw;
}


@media screen and (min-width: 1001px) {
  .footer {
    flex-direction: row;
    height: fit-content
  }

  .img-container-footer {
      width: 30vw;
  }
  
  .img-container-footer img {
    width: 100%;
    margin-left: 2vw;
    margin-top: 0vw;
  }

  .call-footer {
    margin-right: 5vw;
    width: fit-content;
    height: fit-content;
    background-color: #00000000;
    margin-top: 0%;
  }

  .call-footer a{
    color: #FFD300;
  }
  .call-footer p{
    color: #FFD300;
  }
}