a,
div,
button,
[type="button"] {
  -webkit-appearance: none;
}

.phoneIconMobile {
    width: 3.5vw;
}

.phoneIconWeb {
    width: 0vw;
    display: none;
}


@media screen and (min-width: 1001px) {
.phoneIconWeb {
    width: 1.5vw;
    display:block;
}

.phoneIconMobile {
        display: none;
    }
}