.bottom-panel-img img {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 1001px) {

  .bottom-panel-img {
    width: 100%;
    align-self: center;
  }
}
