#msform select {
    width: 100%;
    height: 10vw;
    border-width: 0;
    border-radius: 35vw;
    padding-left: 4vw;
    padding-right: 4vw;
    font-size: 22px;
    font-family: "Poppins";
    background: url("data:image/svg+xml,%3Csvg width='26' height='13' viewBox='0 0 26 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 13L0.875645 0.25L25.1244 0.249998L13 13Z' fill='%23080808'/%3E%3C/svg%3E") no-repeat 100% 50%;
    background-color: #FFD300;
    appearance: none;
    background-position: right 2vw center;
}

@media screen and (min-width: 1001px) {
    #msform select {
        height: 4vw;
        width: 30%;
        margin-bottom: 1vw;
    }

    .dropdown-container{
        display: flex;
        justify-content: left;
    }
}
